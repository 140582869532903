/** @jsx jsx */
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { Box, Flex, Heading, jsx, Text } from 'theme-ui';

const ArticleCard = ({ article }) => {
  const { title, slug, description, image } = article;
  return (
    <Link to={`/articles/${slug}`}>
      <Flex
        sx={{
          flexDirection: ['column'],
        }}
      >
        {image && (
          <Box sx={{ width: '100%' }}>
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={image.title}
              sx={{
                height: '100%',
                py: ['2px'],
              }}
              imgStyle={{ objectPosition: 'top' }}
            />
          </Box>
        )}
        <Heading
          as="h2"
          variant="h2"
          sx={{
            textAlign: ['center', null, 'left'],
            color: 'primary',
            variant: ['text.h3', 'text.h3', 'text.h2'],
            marginTop: ['16px', null, '36px'],
            marginBottom: ['8px', null, '32px'],
          }}
        >
          {title}
        </Heading>
        {description && (
          <Text
            sx={{
              variant: 'text.body',
              color: 'primary',
              textAlign: ['center', null, 'left'],
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html,
              }}
            />
          </Text>
        )}
      </Flex>
    </Link>
  );
};

ArticleCard.propTypes = {
  article: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    body: PropTypes.shape({}),
    description: PropTypes.shape({}),
    image: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
};

export default ArticleCard;
