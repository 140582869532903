import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { Box, Button, Card, Flex, Heading, jsx, Text } from 'theme-ui';
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy';
import CollectionLink from '~/components/Collection/Link';

const CollectionCard = ({ collection }) => {
  const { id, slug, title, description, mainImage } = collection;
  const translate = useTranslate();

  return (
    <Card
      sx={{
        padding: ['0', '0', '0'],
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CollectionLink title={title} slug={slug} id={id}>
        <GatsbyImage
          image={mainImage.gatsbyImageData}
          alt={mainImage.title}
          key={mainImage.title}
          sx={{
            width: '100%',
          }}
        />
      </CollectionLink>
      <Flex
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column',
          flexGrow: 1,
          px: ['16px', null, '24px'],
          py: ['16px', '24px', '32px'],
        }}
      >
        <CollectionLink
          title={title}
          slug={slug}
          id={id}
          sx={{
            textTransform: 'none',
            textAlign: 'center',
          }}
        >
          <Text
            variant="textLink"
            sx={{
              paddingBottom: ['8px', null, '12px'],
              paddingTop: ['8px', null, null],
            }}
          >
            {translate('collections.title')}
          </Text>

          <Heading
            as="h2"
            variant="h2"
            sx={{
              paddingBottom: ['8px', null, '12px'],
            }}
          >
            {title}
          </Heading>
        </CollectionLink>
        <Flex
          color="white"
          sx={{
            flexDirection: 'column',
            textAlign: 'center',
            flexGrow: 1,
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              variant: ['text.h4', 'text.h4', 'text.h4'],
              textAlign: 'center',
              '& p': {
                margin: 2,
              },
            }}
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html,
            }}
          />
          <CollectionLink title={title} slug={slug} id={id}>
            <Button
              sx={{
                color: 'white',
                backgroundColor: 'primary',
                '&:hover': {
                  color: 'primary',
                  backgroundColor: 'secondary',
                },
                width: '100%',
              }}
            >
              {translate('collections.shop_button')}
            </Button>
          </CollectionLink>
        </Flex>
      </Flex>
    </Card>
  );
};

CollectionCard.propTypes = {
  collection: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    description: PropTypes.shape({}),
    mainImage: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
};

export default CollectionCard;
