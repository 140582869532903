/** @jsx jsx */
import PropTypes from 'prop-types';
import { Flex, jsx, Box, Spinner } from 'theme-ui';

const ColorSelector = ({
  handleChange,
  options = [],
  setSelectedColor,
  selectedColor,
  selectVariant = () => {},
  forwardSX = {},
  forwardButtonSX = {},
  stockInfo = null,
  sku,
  ...props
}) => {
  const { isLoading } = props;

  options.sort((a, b) => a.presentation - b.presentation);

  return (
    <Flex sx={{ alignItems: 'center', ...forwardSX }}>
      {isLoading && <Spinner color="inherit" />}
      {!isLoading &&
        options.map((option) => {
          const anySizeInStock = stockInfo?.find(
            (s) => s.color.slug === option.slug && s.totalOnHand
          );
          return (
            <Box
              sx={{
                height: selectedColor === option ? '40px' : '34px',
                width: selectedColor === option ? '40px' : '34px',
                borderRadius: '50%',
                backgroundColor: option.optionalRendering,
                opacity: stockInfo && !anySizeInStock ? 0.3 : 1,
                pointerEvents: stockInfo && !anySizeInStock ? 'none' : 'all',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '6px',
                cursor: "url('/images/blood.svg'), pointer",
                border:
                  stockInfo && selectedColor.slug === option.slug ? '1.5px solid #e6fc5c' : '',
                ...forwardButtonSX,
              }}
              key={option.presentation}
              onClick={() => {
                setSelectedColor(option);
                selectVariant(option);
              }}
            />
          );
        })}
    </Flex>
  );
};

ColorSelector.propTypes = {
  handleChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      presentation: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    })
  ),
};

export default ColorSelector;
