/** @jsx jsx */
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { jsx } from 'theme-ui';
import ContentHero from '~/components/Content/Hero';
import ContentSection from '~/components/Content/Section';

const ContentPage = ({ page }) => (
  <Fragment>
    {page.hero && <ContentHero hero={page.hero} />}
    {page.sections &&
      page.sections.map((section) => <ContentSection key={section.id} section={section} />)}
  </Fragment>
);

ContentPage.propTypes = {
  page: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }),
};

export default ContentPage;
