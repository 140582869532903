/** @jsx jsx */
import { Link } from 'gatsby';
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import { useAnalytics } from '@chordcommerce/gatsby-theme-autonomy';
import useProduct from '~/hooks/components/use-product';

const ProductLink = ({ slug, children, sx, ...props }) => {
  const product = useProduct({ slug });
  const { trackProductClicked } = useAnalytics();

  const url = `/products/${slug}/`;

  return (
    <Link
      to={url}
      onClick={() => trackProductClicked({ product })}
      sx={{
        ...sx,
      }}
      {...props}
    >
      {children}
    </Link>
  );
};

ProductLink.propTypes = {
  slug: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default ProductLink;
