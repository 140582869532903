/** @jsx jsx */
import PropTypes from 'prop-types';
import { Container, Box, Flex, jsx } from 'theme-ui';
import ArticleSection from './Article';
import CollectionSection from './Collection';
import ContentCardSection from './ContentCard';
import ProductSection from './Product';
import QuoteSection from './Quote';
import TextSection from './Text';

const mediaType = (media) => media[0].__typename;

const ContentSection = ({ section }) => (
  <Flex
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}
  >
    <Container>
      {section.media && section.media.length > 0 ? (
        <Box>
          {mediaType(section.media) === 'ContentfulProduct' && (
            <ProductSection
              products={section.media}
              title={section.title}
              description={section.description}
              cta={section.cta}
            />
          )}

          {mediaType(section.media) === 'ContentfulCollection' && (
            <CollectionSection
              collections={section.media}
              title={section.title}
              description={section.description}
            />
          )}

          {mediaType(section.media) === 'ContentfulQuote' && (
            <QuoteSection quotes={section.media} />
          )}

          {mediaType(section.media) === 'ContentfulContentCard' && (
            <TextSection title={section.title} description={section.description} cta={section.cta}>
              <ContentCardSection contentCards={section.media} />
            </TextSection>
          )}

          {mediaType(section.media) === 'ContentfulArticle' && (
            <ArticleSection
              title={section.title}
              description={section.description}
              articles={section.media}
            />
          )}
        </Box>
      ) : (
        <TextSection
          title={section.title}
          description={section.description}
          cta={section.cta}
          heading={section.heading}
          subheading={section.subheading}
          copy={section.copy}
        />
      )}
    </Container>
  </Flex>
);

ContentSection.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.shape({
      description: PropTypes.string,
    }),
    media: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default ContentSection;
