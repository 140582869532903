/** @jsx jsx */
import PropTypes from 'prop-types';
import { Flex, jsx } from 'theme-ui';
import Quote from '~/components/Generic/Quote';

const QuoteSection = ({ quotes }) => (
  <Flex
    sx={{
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'altBackground',
    }}
  >
    {quotes.map((quote, index) => (
      <Quote key={index} quote={quote} />
    ))}
  </Flex>
);

QuoteSection.propTypes = {
  quotes: PropTypes.arrayOf(PropTypes.shape({})),
};

export default QuoteSection;
