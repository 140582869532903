/** @jsx jsx */
import { Link } from 'gatsby';
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import { useAnalytics } from '@chordcommerce/gatsby-theme-autonomy';

const CollectionLink = ({ id, title, slug, children, sx, ...props }) => {
  const { trackCollectionClicked } = useAnalytics();

  const url = `/shop/?collection=${slug}`;

  return (
    <Link
      to={url}
      onClick={() => trackCollectionClicked({ collection: { title, slug, url, collectionId: id } })}
      sx={{
        ...sx,
      }}
      {...props}
    >
      {children}
    </Link>
  );
};

CollectionLink.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CollectionLink;
