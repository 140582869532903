/** @jsx jsx */
import PropTypes from 'prop-types';
import { Flex, jsx, Box, Spinner, Text, useThemeUI } from 'theme-ui';

const SizeSelector = ({
  handleChange,
  options = [],
  setSelectedSize,
  selectedSize,
  selectVariant = () => {},
  forwardSX = {},
  forwardButtonSX = {},
  forwardTextSX = {},
  selectedColor = { slug: null },
  stockInfo = null,
  ...props
}) => {
  const { theme } = useThemeUI();
  const { isLoading } = props;
  const sizeOrder = { S: 0, M: 1, L: 2, XL: 3 };

  options.sort((a, b) => sizeOrder[a.presentation] - sizeOrder[b.presentation]);
  return (
    <Flex
      sx={{
        minWidth: '80px',
        ...forwardSX,
      }}
    >
      {isLoading && <Spinner color="inherit" />}
      {!isLoading &&
        options.map((option) => {
          // Keep the Selected Color as base and find all the size options for that color and check the stock for that.
          const inStock = stockInfo?.find(
            (s) => s?.color?.slug === selectedColor?.slug && s?.size?.slug === option?.slug
          );
          return (
            <Box
              sx={{
                height: '38px',
                width: '41px',
                borderRadius: '5px',
                backgroundColor: `${
                  selectedSize === option ? theme.colors.alizarinCrimson : 'white'
                }`,
                color: `${selectedSize === option ? 'white' : 'black'}`,
                opacity: inStock ? (inStock.totalOnHand ? 1 : 0.3) : 1,
                pointerEvents: inStock ? (inStock.totalOnHand ? 'all' : 'none') : 'all',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 2px',
                cursor: "url('/images/blood.svg'), pointer",
                borderColor: selectedSize.slug === option.slug ? 'canary' : '#fff',
                borderWidth: '1px',
                borderStyle: 'solid',
                ...forwardButtonSX,
              }}
              key={option.presentation}
              onClick={() => {
                setSelectedSize(option);
                selectVariant(option);
              }}
            >
              <Text
                sx={{
                  fontWeight: 700,
                  fontStyle: 'italic',
                  fontSize: '15px',
                  ...forwardTextSX,
                }}
              >
                {option.presentation}
              </Text>
            </Box>
          );
        })}
    </Flex>
  );
};

SizeSelector.propTypes = {
  handleChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      presentation: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    })
  ),
};

export default SizeSelector;
