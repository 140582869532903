/** @jsx jsx */
import React from 'react';
import { Box, Container, Flex, Heading, jsx } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';

const ImageHeroWithGradient = ({ heading, image, subheading, ...props }) => (
  <Container variant="fullwidth" {...props}>
    <Flex
      sx={{
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <GatsbyImage
          image={image.gatsbyImageData}
          sx={{
            height: '100%',
            py: ['2px'],
            minHeight: '108px',
            maxHeight: ['240px', '280px', '450px'],
          }}
        />
      </Box>
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background: [
            'rgba(0,0,0,.3)',
            'linear-gradient(to right,rgba(0,0,0,.2), rgba(0,0,0,.2) 60%, rgba(0,0,0,0))',
            null,
          ],
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            width: ['700px'],
          }}
        >
          {heading && (
            <Heading
              as="h1"
              variant="h2"
              sx={{
                color: 'background',
                textTransform: 'uppercase',
                textAlign: 'center',
              }}
            >
              {heading.heading}
            </Heading>
          )}

          {subheading && (
            <Heading
              as="h3"
              variant="subhead"
              sx={{
                marginTop: '1rem',
                color: 'background',
                textTransform: 'uppercase',
                textAlign: 'center',
              }}
            >
              {subheading.subheading}
            </Heading>
          )}
        </Box>
      </Flex>
    </Flex>
  </Container>
);

const ImageHeroWithoutGradient = ({ heading, image, subheading, readability, ...props }) => (
  <Container variant="fullWidth" {...props}>
    <Flex
      sx={{
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
      }}
    >
      <Box
        sx={{ width: '100%', position: 'relative', '& .gatsby-image-wrapper': { width: '100vw' } }}
      >
        <GatsbyImage
          imgStyle={{ objectFit: 'cover' }}
          image={image.gatsbyImageData}
          sx={{
            height: '100%',
            maxHeight: '40vh',
            zIndex: 1,
            opacity: readability ? 0.67 : 1,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'black',
            zIndex: 0,
          }}
        />
      </Box>
      <Flex
        sx={{
          textAlign: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: ['2rem 1rem', '5.5rem'],
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1,
        }}
      >
        {heading && (
          <>
            <Heading as="h1" sx={{ height: '0px', opacity: '0' }}>
              {heading.heading}
            </Heading>
            <Heading as="h2" variant="h2">
              {heading.heading}
            </Heading>
          </>
        )}

        {subheading && (
          <Heading as="h3" variant="h3">
            {subheading.subheading}
          </Heading>
        )}
      </Flex>
    </Flex>
  </Container>
);

export const ImageHero = ({ gradient = false, ...props }) =>
  gradient ? <ImageHeroWithGradient {...props} /> : <ImageHeroWithoutGradient {...props} />;

export default ImageHero;
