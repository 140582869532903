import { updateStoredTags } from '~/utils/misc/storeTags';

export const handlePreBuiltBoxes = (product, boxContext, tagSubfix = '') => {
  const { setDynamicButton, setTotalsArr, setSelectedInterval } = boxContext;
  const boxArr = [
    product.tamponLight,
    product.tamponRegular,
    product.tamponSuper,
    product.padDay,
    product.padNight,
    product.longNightPad,
    product.liners,
    0,
    0,
    0,
  ];
  updateStoredTags({
    tagKey: 'preBuildBox',
    tagValue: { tag: `${product.title.split(' ').join('_')}${tagSubfix}`, edited: false },
  });
  setTotalsArr(boxArr);
  setSelectedInterval(product.defaultSubscriptionInterval);
  setTimeout(() => {
    setDynamicButton(true);
  }, 50);
};

export const handleBundles = async (product, addSubscription, setCartIsOpen, tagSubfix = '') => {
  const { sku } = product;
  try {
    await addSubscription({
      sku,
      quantity: 1,
      endDate: null,
      interval: {
        length: product?.defaultSubscriptionInterval?.length || 1,
        unit: 'month',
      },
    });
    const tagName = product.displayName
      .replace('-pack', '')
      .replace(/[^A-Za-z ]+/g, '')
      .trim()
      .replaceAll(' ', '_')
      .replaceAll('__', '_');
    updateStoredTags({
      tagKey: tagName,
      tagValue: { tag: `${tagName}${tagSubfix}`, edited: false },
    });
  } catch (error) {
    console.log(error);
  }
  setCartIsOpen(true);
};

export const prebuildBoxClick = (product, boxContext, openModal) => {
  const { setTotalsArr, setSelectedInterval } = boxContext;
  const boxArr = [
    product.tamponLight,
    product.tamponRegular,
    product.tamponSuper,
    product.padDay,
    product.padNight,
    product.longNightPad,
    product.liners,
    0,
    0,
    0,
  ];
  setTotalsArr(boxArr);
  setSelectedInterval(product.defaultSubscriptionInterval);
  openModal();
};
