/** @jsx jsx */
import PropTypes from 'prop-types';
import { Text, Flex, Box, Heading, jsx } from 'theme-ui';

import RichTextRenderer from '~/components/Content/RichText';
import Spacer from '~/components/Generic/Layout/Spacer';

const TextSection = ({ heading, subheading, copy }) => (
  <Flex
    sx={{
      flexDirection: 'column',
      alignItems: 'center',
      width: ['100%', '90%', '80%'],
      marginTop: ['24px', '24px', '96px'],
      marginBottom: ['24px', '24px', '96px'],
      color: 'alizarinCrimson',
    }}
  >
    <Flex sx={{ flexDirection: 'column', maxWidth: '90vw' }}>
      <Heading as="h1" variant="h2">
        <RichTextRenderer richText={heading} />
      </Heading>
      <Heading
        as="h3"
        variant="h3"
        sx={{
          alignSelf: 'flex-end',
          fontStyle: 'italic',
          '& > p': { margin: 0 },
        }}
      >
        <RichTextRenderer richText={subheading} />
      </Heading>
    </Flex>
    <Spacer height="75px" />
    <Text sx={{ fontSize: '13px', maxWidth: '90vw' }}>
      <RichTextRenderer richText={copy} />
    </Text>
  </Flex>
);

TextSection.propTypes = {
  cta: PropTypes.shape({}),
  heading: PropTypes.shape({}),
  subheading: PropTypes.shape({}),
  copy: PropTypes.shape({}),
};

export default TextSection;
