/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/** @jsx jsx */
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Box, Flex, Card, Button, jsx, Text, Spinner } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';
import { useCart } from '@chordcommerce/gatsby-theme-autonomy';
import ProductLink from '~/components/Product/Link';
import { useAllVariantAvailability } from '~/hooks/components/useVariantAvailability';

import BoxBuilderPopUpContext, { useBoxBuilder } from '~/contexts/BoxBuilderPopUpContext';
import CartContext from '~/contexts/CartContext';
import {
  handleBundles,
  handlePreBuiltBoxes,
  prebuildBoxClick,
} from '~/utils/addToBag/addToBagWithTagging';
import ProductSizeSelector from '~/components/Product/SizeSelector';
import ProductColorSelector from '~/components/Product/ColorSelector';

const ProductCard = ({
  alternateImage,
  boxBuilderRow,
  slug,
  product,
  price = 0,
  isPreBuiltBox,
  name,
  closeAll = false,
  ...props
}) => {
  const { variants, optionTypes, sku } = product;
  const firstLoadRef = useRef(true);
  const { openModal } = useBoxBuilder();

  const [currentVariant, setCurrentVariant] = useState(variants?.[0] ?? sku);
  const [isLoading, setIsLoading] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const { addToCart, addSubscription, cart } = useCart();

  const [isCartOpen, setCartIsOpen] = useContext(CartContext);
  const boxContext = useContext(BoxBuilderPopUpContext);

  const stockInfo = useAllVariantAvailability({ product });

  const hasOptions = variants?.length > 1 && sku !== 'gift-card';
  const alreadyInCart = cart?.lineItems?.find((item) => item?.variant?.sku === sku);

  // if boxbuilder got to PDP & open the widget
  const isBoxBuilder = slug === 'box-builder' ? 'box-builder?openWidget' : null;
  const bundlesSKUs = ['travel-20ct', 'august-sample-longnight', 'august-sample'];
  const hasSubscriptionOptions = !!product?.subscription || !!product?.defaultSubscriptionInterval;
  const isGiftCard = slug === 'gift-card';
  const isBundle = bundlesSKUs.includes(product.sku);
  const badgeText = isBoxBuilder
    ? 'Build Your Box'
    : isBundle
    ? 'Bundle'
    : isPreBuiltBox
    ? 'Customizable'
    : '';

  const basePrice = isBoxBuilder ? 'From $ 12.78' : `$${price.toFixed(2)}`;
  const subPrice = isBoxBuilder ? '' : `$${Number(price - price * 0.1).toFixed(2)}`;

  // Disable button if the product can only be added one time in cart
  const oneTimeButton = hasSubscriptionOptions && alreadyInCart && !isBoxBuilder;

  const shopAllAddtoCart = async () => {
    const tagSubfix = '_Shop';
    setIsLoading(true);
    if (isBoxBuilder || isGiftCard) {
      navigate(`/products/${isBoxBuilder ?? slug}`);
      setIsLoading(false);
    } else if (isBundle) {
      await handleBundles(product, addSubscription, setCartIsOpen, tagSubfix);
    } else if (isPreBuiltBox) {
      await handlePreBuiltBoxes(product, boxContext, tagSubfix);
    } else if (currentVariant?.sku || sku) {
      await addToCart({ sku: currentVariant?.sku ?? sku, quantity: 1 });
      setCartIsOpen(true);
    }
  };

  useEffect(() => {
    isCartOpen && setIsLoading(false);
  }, [isCartOpen]);

  const colors = optionTypes?.find((o) => o.slug === 'color')?.optionValues ?? [];

  const sizes = optionTypes?.find((o) => o.slug === 'size')?.optionValues ?? [];

  const [selectedColor, setSelectedColor] = useState(colors ? colors[0] : null);

  const [selectedSize, setSelectedSize] = useState(sizes ? sizes[0] : null);

  useLayoutEffect(() => {
    if (stockInfo && firstLoadRef.current) {
      const firstInStock = stockInfo?.find((s) => s.totalOnHand > 0);
      if (firstInStock) {
        const firstVariantInStock = product?.variants?.find((v) => v?.sku === firstInStock?.sku);
        setCurrentVariant(firstVariantInStock);
        setSelectedColor(firstInStock.color);
        setSelectedSize(firstInStock.size);
        firstLoadRef.current = false;
      }
    }
  }, [stockInfo]);

  useEffect(() => {
    if (stockInfo && selectedColor && !firstLoadRef.current) {
      const firstSizeInColor = stockInfo?.find(
        (s) => s.totalOnHand > 0 && s?.color?.slug === selectedColor?.slug
      );
      const newVariant = product?.variants?.find((v) => v?.sku === firstSizeInColor?.sku);
      firstSizeInColor && setSelectedSize(firstSizeInColor.size);
      setCurrentVariant(newVariant);
    }
  }, [selectedColor]);

  useEffect(() => {
    if (stockInfo && selectedSize && !firstLoadRef.current) {
      const newVariant = product?.variants?.find(
        (v) =>
          v?.optionValues.find((o) => o.slug === selectedColor.slug) &&
          v?.optionValues.find((o) => o.slug === selectedSize.slug)
      );
      setCurrentVariant(newVariant);
    }
  }, [selectedSize]);

  useEffect(() => {
    if (showOptions) {
      setTimeout(() => {
        setShowOptions(false);
      }, 100);
    }
  }, [closeAll]);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        boxShadow: 'none',
        backgroundColor: 'inherit',
        padding: '2rem 0',
        height: 'auto',
        minHeight: ['37rem', '37rem', '42rem'],
        maxHeight: showOptions ? ['58rem', '58rem', '62rem'] : ['37rem', '37rem', '42rem'],
        '&:hover': {
          '.hiddenOnHover': {
            opacity: 0,
          },
          '.visibleOnHover': {
            opacity: 1,
          },
        },
      }}
      {...props}
    >
      {/* Image Section */}
      <Box
        className="product-image"
        sx={{ mt: badgeText ? '' : '2.6rem', mb: showOptions ? ['1rem', '1rem', '2.1rem'] : 0 }}
      >
        {badgeText && (
          <Text
            sx={{
              display: 'block',
              width: 'fit-content',
              border: '1px solid #69102C',
              p: '0.5rem 1rem',
              borderRadius: '10rem',
              color: 'darkTan',
              fontSize: ['0.9rem', '0.9rem', '1.2rem'],
              textTransform: 'uppercase',
              fontWeight: 700,
              backgroundColor: 'lightPurple',
            }}
          >
            {badgeText}
          </Text>
        )}
        <ProductLink
          to={isPreBuiltBox ? '/shop' : `/products/${isBoxBuilder ?? slug}`}
          onClick={(e) => {
            if (isPreBuiltBox) {
              e.preventDefault();
              prebuildBoxClick(product, boxContext, openModal);
            }
          }}
          aria-label={`Link to ${product.name} page`}
          slug={slug}
          id={`image-${slug}-${Math.floor(Math.random() * 100)}`}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            '.gatsby-image-wrapper img': {
              height: ['15.5rem', '15.5rem', '20rem'],
              maxHeight: ['15.5rem', '15.5rem', '20rem'],
              width: '100%',
              zIndex: 1,
              display: 'flex',
              justifyContent: 'center',
              my: '1rem',
            },
          }}
        >
          <GatsbyImage
            image={
              alternateImage ? alternateImage.gatsbyImageData : product.mainImage.gatsbyImageData
            }
            alt={currentVariant?.mainImage?.title ?? name}
            imgStyle={{ objectFit: 'contain' }}
          />
        </ProductLink>
        <ProductLink
          to={`/products/${isBoxBuilder ?? slug}`}
          tabIndex="-1"
          aria-label={`Link to ${product.name} page`}
          slug={slug}
          sx={{
            fontSize: ['1.4rem', '1.4rem', '1.8rem'],
            fontWeight: 'bold',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            mb: '1.5rem',
          }}
          className="product-name"
        >
          <Text
            sx={{
              textTransform: 'uppercase',
              mb: '0.6rem',
            }}
          >
            {name}
          </Text>
          <Box>
            <Text
              sx={{
                color: 'darkTan',
                mr: '1rem',
                textDecoration: isBoxBuilder || !hasSubscriptionOptions ? '' : 'line-through',
              }}
            >
              {basePrice}
            </Text>
            {hasSubscriptionOptions && <Text sx={{ color: 'discountGreen' }}>{subPrice}</Text>}
          </Box>
        </ProductLink>
      </Box>
      {/* Button Section */}
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: boxBuilderRow ? 'flex-start' : ['flex-end', 'flex-end', 'flex-end'],
        }}
        tabIndex="-1"
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        <Flex sx={{ flexDirection: 'column' }}>
          {stockInfo && variants && (
            <Flex
              sx={{
                height: showOptions ? ['21rem', '21rem', '18rem'] : 0,
                maxHeight: showOptions ? ['21rem', '21rem', '18rem'] : 0,
                opacity: showOptions ? 1 : 0,
                transition: 'all 0.5s ease-in-out',
                borderRadius: '1rem 1rem 0 0',
                backgroundColor: 'alizarinCrimson',
                flexDirection: 'column',
                p: showOptions ? ['1rem', '1rem', '2rem'] : 0,
                justifyContent: 'center',
              }}
            >
              <Flex
                sx={{
                  opacity: showOptions ? 1 : 0,
                  mb: '2rem',
                }}
              >
                <ProductColorSelector
                  sx={{ maxWidth: '8.2rem' }}
                  options={colors}
                  selectedColor={selectedColor}
                  setSelectedColor={setSelectedColor}
                  forwardSX={{ justifyContent: 'center', width: '100%' }}
                  forwardButtonSX={{
                    width: '2.5rem',
                    height: '2.5rem',
                    p: '0.5rem',
                    backgroundClip: 'content-box',
                    m: '0 0 0 0.5rem',
                  }}
                  stockInfo={stockInfo}
                  sku={sku}
                />
              </Flex>
              <Flex
                sx={{
                  opacity: showOptions ? 1 : 0,
                  width: '100%',
                  justifyContent: 'center',
                  mt: '-0.5rem',
                }}
              >
                <ProductSizeSelector
                  options={sizes}
                  selectedSize={selectedSize}
                  setSelectedSize={setSelectedSize}
                  forwardSX={{ justifyContent: 'center', maxWidth: '20rem', flexWrap: 'wrap' }}
                  forwardButtonSX={{
                    width: '4rem',
                    height: '4rem',
                    borderRadius: '0.5rem',
                    p: '0.5rem',
                    backgroundClip: 'content-box',
                    m: ['0.5rem 0.25rem', '0.5rem 0.25rem', '0.5rem 0.5rem'],
                    color: '#fff',
                    backgroundColor: 'inherit',
                  }}
                  forwardTextSX={{
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '1.4rem',
                  }}
                  selectedColor={selectedColor}
                  stockInfo={stockInfo}
                />
              </Flex>
            </Flex>
          )}
          <Button
            sx={{
              height: '5rem',
              fontSize: ['1.4rem', '1.6rem', '1.6rem'],
              borderRadius: showOptions ? '0 0 1rem 1rem ' : '1rem',
              pointerEvents: oneTimeButton ? 'none' : 'all',
              opacity: oneTimeButton ? 0.65 : 1,
              px: '0.25rem !important',
              width: '100%',
              justifyContent: 'center',
              zIndex: 5,
            }}
            variant="buttons.ctaHighlight"
            onClick={() => (hasOptions && !showOptions ? setShowOptions(true) : shopAllAddtoCart())}
          >
            {isLoading ? (
              <Spinner data-testid="spinner" size="15" color="inherit" />
            ) : isBoxBuilder ? (
              'Build Your Box'
            ) : isGiftCard ? (
              'Get Started'
            ) : oneTimeButton ? (
              'Already In Cart'
            ) : (
              'Quick Add'
            )}
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};

export default ProductCard;
