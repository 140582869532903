/** @jsx jsx */

import { useRef, useState } from 'react';
import { Box, jsx, Flex } from 'theme-ui';

import ProductCard from '~/components/Product/Card';

import useOnScreen from '~/hooks/components/use-on-screen';
import useWindowSize from '~/hooks/components/use-window-size';

const ProductList = ({ products }) => {
  const { width } = useWindowSize();
  const ref = useRef();
  const onScreen = useOnScreen(ref, '0px', true);
  const [closeAll, setCloseAll] = useState(false);

  return (
    <Flex
      sx={{
        flexFlow: 'row wrap',
        justifyContent: [width < 350 ? 'center' : 'space-around', 'space-around', 'space-around'],
        width: '100%',
        '> div': {},
      }}
      ref={ref}
    >
      {products?.map((product, idx) => {
        const slug = product?.slug ?? product?.title?.split(' ').join('-');
        const alternateImage = product?.boxImage;
        // Pre Built Boxes dont have a SKU as they are essentially same as Build-A-Box but prebuilt and customizable
        const isPreBuiltBox = !product?.sku;
        return (
          <Box
            key={slug}
            sx={{
              mx: width < 350 ? '1rem' : 'min(2rem,auto)',
              '> div': {
                maxWidth: ['15.5rem', '24rem', '31.5rem'],
                minWidth: ['15.5rem', '24rem', '31.5rem'],
                width: 'auto',
              },
            }}
            onBlur={(e) => {
              const { currentTarget, relatedTarget } = e;
              if (currentTarget.contains(relatedTarget)) return;
              setCloseAll((p) => !p);
            }}
          >
            <ProductCard
              className={`fade-in-up ${onScreen ? 'visible' : ''}`}
              product={product}
              slug={isPreBuiltBox ? '' : slug}
              alternateImage={alternateImage}
              isPreBuiltBox={isPreBuiltBox}
              price={product?.price ?? product?.displayPrice}
              name={product?.name ?? product?.title}
              closeAll={closeAll}
              sx={{
                transitionDelay: `${0.25 * idx}s`,
                '& .gatsby-image-wrapper': { animationDelay: `${0.01 * idx}s` },
              }}
            />
          </Box>
        );
      })}
    </Flex>
  );
};

export default ProductList;
